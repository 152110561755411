import React, { createContext, useContext } from 'react';

const PagePropsContext = createContext();

export const usePageProps = () => {
    return useContext(PagePropsContext);
};

export const PagePropsProvider = ({ children, value }) => {
    return (
        <PagePropsContext.Provider value={value}>
            {children}
        </PagePropsContext.Provider>
    );
};
